.my-app[data-v-31ad00d8] {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: sans-serif;
  font-size: 2rem;
  height: 50vh;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
}
.my-app a[data-v-31ad00d8] {
    color: #09e;
    text-decoration: none;
}
